<template>
  <PatientPage :patientNo="patientNo" pageTitle="Hypo episodes">
    <v-row>
      <v-col>
        <HypoFormList :patientNo="patientNo" />
      </v-col>
    </v-row>
  </PatientPage>
</template>

<script>
import PatientPage from '@/components/patient/PatientPage'
import HypoFormList from '@/components/hypo-form/HypoFormList'
export default {
  name: 'HypoOverview',
  components: { HypoFormList, PatientPage },
  props: { patientNo: { type: String, required: true } },
}
</script>
