<template>
  <v-card class="rounded-lg">
    <HypoFormTable
      :patient-no="patientNo"
      :items="items"
      :loading="isLoading"
      :isLoadErrored="isLoadErrored"
    />
  </v-card>
</template>

<script>
import loadingState from '@/constants/loadingState'
import {
  hypoListMapActions,
  hypoListMapGetters,
} from '@/store/modules/hypoListModule'
import HypoFormTable from './HypoFormTable'

export default {
  name: 'HypoFormList',
  components: { HypoFormTable },
  props: {
    patientNo: { type: String, required: true },
  },
  computed: {
    ...hypoListMapGetters(),
    isLoading() {
      return this.hypoListLoadingState === loadingState.LOADING
    },
    isLoadErrored() {
      return this.hypoListLoadingState === loadingState.LOAD_ERRORED
    },
    items() {
      return this.hypoListItems
    },
  },
  methods: {
    ...hypoListMapActions(),
  },
  created() {
    this.hypoListLoadDataRequested(this.patientNo)
  },
}
</script>
