<template>
  <v-data-table
    :headers="getTableHeaders()"
    :items="items"
    data-testid="hypo-form-table"
    :class="{ 'no-data-table': !hasData }"
    :hide-default-header="!hasData"
    :footer-props="{
      'items-per-page-options': itemsPerPageOptions,
    }"
  >
    <template #top="{ pagination, options, updateOptions }">
      <v-data-footer
        v-if="!hideTopPagination"
        :pagination="pagination"
        :options="options"
        @update:options="updateOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        :items-per-page-options="itemsPerPageOptions"
        class="top-footer-item"
      >
      </v-data-footer>
    </template>

    <template #item.startedAt="{ item }">
      <DateFormat
        data-testid="started-at-date"
        :value="item.startedAt"
        :class="{ 'is-deleted': item.isDeleted }"
      />
    </template>
    '
    <template #item.started-at-time="{ item }">
      <DateFormat
        data-testid="started-at-time"
        :value="item.startedAt"
        :format="timeFormatString"
        :class="{ 'is-deleted': item.isDeleted }"
      />
    </template>

    <template #item.lowestValue="{ item }">
      <HypoFormDialog
        v-if="item.hypoId"
        :patientNo="patientNo"
        :hypoId="item.hypoId"
      >
        <div class="link-span" data-testid="bgm-hypo-cta">
          <SmbgValue
            v-if="item.lowestValue"
            :measure="item.lowestValue"
            :is-deleted="item.isDeleted"
          >
            <v-icon small class="ml-1 is-hypo no-line-through"
              >mdi-open-in-new</v-icon
            >
          </SmbgValue>
          <div
            v-else
            class="padding-left"
            :class="[{ 'is-hypo': !item.isDeleted }]"
          >
            <span class="is-hypo" :class="[{ 'is-deleted': item.isDeleted }]"
              >No BG value</span
            >
            <v-icon small class="ml-1 is-hypo no-line-through"
              >mdi-open-in-new</v-icon
            >
          </div>
        </div>
      </HypoFormDialog>
      <SmbgValue v-else-if="item.lowestValue" :measure="item.lowestValue">
      </SmbgValue>
      <div v-else>—</div>
    </template>

    <template #item.review-status="{ item }">
      <v-chip
        v-if="!item.hypoId"
        label
        color="#FDF7E6"
        text-color="#333"
        class="full-width"
        data-testid="hypo-status-not-submitted"
        small
      >
        Hypo form not submitted
      </v-chip>

      <v-row v-else-if="item.review" class="no-gutters d-flex align-center">
        <v-col sm="10">
          <SevereChip
            v-if="item.isSevere"
            noMarginLeft
            :showAsDeleted="item.isDeleted"
            class="mr-2"
          />
          <DeletedByPatientChip v-if="item.isDeleted" />
          <v-chip
            v-else-if="item.review.isReviewPending"
            label
            text-color="#001965"
            color="#EBF2FB"
            data-testid="hypo-status-pending-review"
            small
          >
            To be reviewed
          </v-chip>
          <ConfirmedAt
            v-else
            small
            smallFont
            :date="item.review.reviewInfo.reviewedAt.time"
          />
        </v-col>
        <v-col sm="2">
          <HypoFormDialog :hypoId="item.hypoId" :patientNo="patientNo">
            <v-btn
              v-if="isReviewAllowed(item)"
              rounded
              color="secondary"
              small
              class="d-block mx-auto"
              data-testid="btn-hypo-review"
              >Review
            </v-btn>
            <v-btn
              v-else
              small
              outlined
              rounded
              class="d-block mx-auto"
              data-testid="btn-open-hypo"
              >Open</v-btn
            >
          </HypoFormDialog>
        </v-col>
      </v-row>

      <v-card v-else flat class="d-flex justify-end blue--text">
        <v-icon
          :color="item.review.isReviewPending ? '#eee' : '#005AD2'"
          class="mr-2"
          >mdi-checkbox-marked</v-icon
        >
        <HypoFormDialog
          :hypoId="item.hypoId"
          :patientNo="patientNo"
          class="text--primary"
        >
        </HypoFormDialog>
      </v-card>
    </template>

    <template #no-data>
      <template v-if="loading">Loading... Please wait </template>
      <template v-else>
        <UnableToLoadData v-if="isLoadErrored" />

        <div v-else>
          <div class="d-flex align-center justify-center mx-auto my-4">
            <ImgHypoHcp />
          </div>

          <strong class="no-data-text d-block mb-4"
            >There are currently no hypo episode forms<br />available for this
            patient.</strong
          >
        </div>
      </template>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'

import DateFormat from '@/components/DateFormat.vue'
import SmbgValue from '@/components/SmbgValue.vue'
import HypoFormDialog from './HypoFormDialog.vue'
import ConfirmedAt from '../ConfirmedAt.vue'

import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import hourFormatter from '@/utils/date/hourFormatter'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'
import SevereChip from './SevereChip'
import DeletedByPatientChip from './DeletedByPatientChip'

export default Vue.extend({
  name: 'HypoFormTable',
  mixins: [permissionsGettersMixin],
  components: {
    DeletedByPatientChip,
    SevereChip,
    ConfirmedAt,
    DateFormat,
    SmbgValue,
    HypoFormDialog,
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
    ImgHypoHcp: () => import('@/components/images/ImgHypoHcp.vue'),
  },
  props: {
    patientNo: { type: String, required: true },
    items: { type: Array, required: true },
    loading: { type: Boolean, required: false, default: false },
    isLoadErrored: { type: Boolean, required: false, default: false },
    hideTopPagination: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      itemsPerPageOptions,
    }
  },
  computed: {
    ...selectedPatientMapGetters(),
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    timeFormatString() {
      return hourFormatter(this.selectedPatientClockNotation(this.patientNo))
    },
    hasData() {
      return this.items.length > 0
    },
  },
  methods: {
    getTableHeaders() {
      return [
        { text: 'Date', value: 'startedAt', width: '140px', sortable: true },
        {
          text: `Time ${this.clockNotationDisplay}`,
          value: 'started-at-time',
          sortable: false,
        },
        {
          text: 'Lowest BG value',
          value: 'lowestValue',
          sortable: false,
          class: 'padding-left',
        },
        { text: 'Hypo form', value: 'review-status', sortable: false },
      ]
    },
    isReviewAllowed(hypo) {
      return (
        this.isUserAllowedToManagePatient &&
        hypo.review.isReviewPending &&
        !hypo.isDeleted
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.v-data-table {
  .top-footer-item {
    border-top: none;
  }
  ::v-deep thead th {
    background-color: $nn-TB_T98;
    letter-spacing: -0.02em;
    color: $nn-D_T20 !important;
    height: 37px !important;
    &.padding-left {
      padding-left: 50px;
    }
    > span {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }
}

.padding-left {
  padding-left: 30px;
}
.link-span {
  cursor: pointer;
  display: inline-block;
}
.no-line-through {
  text-decoration: none !important;
}
</style>
